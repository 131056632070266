import React from 'react';

export const maintenanceGuidelineConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/check-list',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/complete-maintenance-guideline',
        component: React.lazy(() =>
          import('./components/CompleteMaintenanceGuideline')
        ),
      },
      {
        path: '/update-maintenance-guideline',
        component: React.lazy(() =>
          import('./components/CreateMaintenanceGuideline')
        ),
      },
    ],
  },
];
