import { put, call, select } from '@redux-saga/core/effects';
import {
  httpsPostFetch,
  httpsPostFetchGoogleApi,
  responseBody,
} from '../services/https/post';
import { updateDataWithBranch } from './utils/utils';

const getAuth = (state) => state.auth;

export const operationHttpsPostWithResponse = {
  name: 'HTTPS_POST_WITH_RESPONSE',
  reducer: {
    initialState: { creating: false, created: false, createFailed: false },
    cases: [
      {
        name: 'SEND_HTTPS_POST_WITH_RESPONSE_',
        newState: { creating: true, created: false, createFailed: false },
      },
      {
        name: 'SUCCESS_HTTPS_POST_WITH_RESPONSE_',
        newState: { creating: false, created: true, createFailed: false },
      },
      {
        name: 'FAIL_HTTPS_POST_WITH_RESPONSE_',
        newState: { creating: false, created: false, createFailed: true },
      },
      {
        name: 'CLEAN_HTTPS_POST_WITH_RESPONSE_',
        newState: {
          creating: false,
          created: false,
          createFailed: false,
          response: null,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        sendHttpsPost: (payload) => {
          return {
            type: 'SEND_HTTPS_POST_WITH_RESPONSE_' + name,
            payload: payload,
          };
        },
        successHttpsPost: () => {
          return { type: 'SUCCESS_HTTPS_POST_WITH_RESPONSE_' + name };
        },
        failHttpsPost: (payload) => {
          return {
            type: 'FAIL_HTTPS_POST_WITH_RESPONSE_' + name,
            payload: payload,
          };
        },
        cleanHttpsPost: (payload) => {
          return {
            type: 'CLEAN_HTTPS_POST_WITH_RESPONSE_' + name,
          };
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'SEND_HTTPS_POST_WITH_RESPONSE_',
    function: (name) => {
      return function* sendPost({ payload }) {
        try {
          yield put({ type: 'LOAD_ALERT' });
          const { path, dataCreate, company } = payload;
          let data = { ...dataCreate };
          const auth = yield select(getAuth);
          data = {
            ...updateDataWithBranch(auth, data),
            idResponsibleUser: auth.user.idUser,
            nameResponsibleUser: auth.user.name,
          };
          const response = yield call(httpsPostFetch, path, data, company);

          if (response.status === 200) {
            const responseBodyPayload = yield call(responseBody, response);
            yield put({ type: 'SEND_SUCCESS' });
            const actionName = 'SUCCESS_HTTPS_POST_WITH_RESPONSE_' + name;
            yield put({
              type: actionName,
              payload: { dataLoad: { response: responseBodyPayload } },
            });
            if (responseBodyPayload.isBinary) {
              // Maneja la descarga del archivo binario
              const byteCharacters = atob(responseBodyPayload.fileContent);
              const byteNumbers = new Uint8Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const blob = new Blob([byteNumbers], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download =
                responseBodyPayload.filename?.name ||
                'transferencia_masiva_repuestos_entre_bodegas.xlsx';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }
          } else {
            const responseBodyPayload = yield call(responseBody, response);
            console.log('response error', responseBodyPayload);
            const translateError = yield call(
              httpsPostFetchGoogleApi,
              responseBodyPayload.message
            );
            yield put({
              type: 'SEND_FAILED',
              payload: { dataLoad: { errorMessage: translateError } },
            });
          }
        } catch (e) {
          yield put({
            type: 'SEND_FAILED',
            payload: { dataLoad: { errorMessage: '' } },
          });
          console.log('error https post with response' + name, e);
        }
      };
    },
  },
};
