import { put, take, takeEvery, select } from '@redux-saga/core/effects';
import { loadCollectionQueryFirestore } from '../services/firebase/firestore';
import { processAuthConditions } from './utils/utils';

const getAuth = (state) => state.auth;

export const operationLoad = {
  name: 'LOAD',
  reducer: {
    initialState: { loading: false, loaded: false, loadFailed: false },
    cases: [
      {
        name: 'LOAD_',
        newState: { loading: true, loaded: false, loadFailed: false },
      },
      {
        name: 'LOADED_',
        newState: { loading: false, loaded: true, loadFailed: false },
      },
      {
        name: 'LOAD_FAILED_',
        newState: { loading: false, loaded: false, loadFailed: true },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        load: (payload) => {
          return { type: 'LOAD_' + name, payload: payload };
        },
        loaded: (payload) => {
          return { type: 'LOADED_' + name, payload: payload };
        },
        loadFailed: () => {
          return { type: 'LOAD_FAILED_' + name };
        },
        stopLoad: () => {
          return { type: 'STOP_LOAD_' + name };
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'LOAD_',
    function: (name) => {
      return function* load({ payload }) {
        try {
          yield put({ type: 'LOAD_ALERT' });
          const { path, idName, conditions } = payload;
          const auth = yield select(getAuth);
          const conditionsBase = conditions.concat([]);
          processAuthConditions(auth, conditionsBase);
          const Channel = loadCollectionQueryFirestore(
            path,
            idName,
            conditionsBase,
            auth.user.company
          );
          yield takeEvery(Channel, function* (action) {
            yield put({
              type: 'LOADED_' + name,
              payload: { dataLoad: { list: action.data } },
            });
            yield put({
              type: 'LOADED_ALERT',
            });
          });
          yield take([
            'LOAD_' + name,
            'STOP_LOAD_' + name,
            'STOP_LOAD_EVERYTHING',
          ]);
          Channel.close();
          yield put({
            type: 'LOADED_' + name,
            payload: { dataLoad: { list: [] } },
          });
        } catch (e) {
          const { path, idName, conditions } = payload;
          console.log(
            'error load collection',
            e,
            'path',
            path,
            'idName',
            idName,
            'condiciones',
            conditions
          );
          yield put({
            type: 'LOADED_' + name,
            payload: { dataLoad: { list: [] } },
          });
          yield put({
            type: 'LOADED_ALERT',
          });
          yield put({ type: 'LOADED_FAILED' });
        }
      };
    },
  },
};
