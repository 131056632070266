import { put, call, select } from '@redux-saga/core/effects';
import {
  httpsPostFetch,
  httpsPostFetchGoogleApi,
  responseBody,
} from '../services/https/post';
import { IS_OK_MASSIVE_UPLOAD } from 'shared/constants/ActionTypes';

const getAuth = (state) => state.auth;

export const operationHttpsPostMassiveUpload = {
  name: 'HTTPS_POST_MASSIVE_UPLOAD',
  reducer: {
    initialState: { creating: false, created: false, createFailed: false },
    cases: [
      {
        name: 'SEND_HTTPS_POST_MASSIVE_UPLOAD_',
        newState: { creating: true, created: false, createFailed: false },
      },
      {
        name: 'SUCCESS_HTTPS_POST_MASSIVE_UPLOAD_',
        newState: { creating: false, created: true, createFailed: false },
      },
      {
        name: 'FAIL_HTTPS_POST_MASSIVE_UPLOAD_',
        newState: { creating: false, created: false, createFailed: true },
      },
      {
        name: 'CLEAN_HTTPS_POST_MASSIVE_UPLOAD_',
        newState: {
          creating: false,
          created: false,
          createFailed: false,
          response: null,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        sendHttpsPost: (payload) => {
          return {
            type: 'SEND_HTTPS_POST_MASSIVE_UPLOAD_' + name,
            payload: payload,
          };
        },
        successHttpsPost: () => {
          return { type: 'SUCCESS_HTTPS_POST_MASSIVE_UPLOAD_' + name };
        },
        failHttpsPost: (payload) => {
          return {
            type: 'FAIL_HTTPS_POST_MASSIVE_UPLOAD_' + name,
            payload: payload,
          };
        },
        cleanHttpsPost: (payload) => {
          return {
            type: 'CLEAN_HTTPS_POST_MASSIVE_UPLOAD_' + name,
          };
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'SEND_HTTPS_POST_MASSIVE_UPLOAD_',
    function: (name) => {
      return function* sendPost({ payload }) {
        try {
          yield put({ type: 'LOAD_ALERT' });
          const { path, dataCreate, company } = payload;
          let data = { ...dataCreate };
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== 'todos'
          ) {
            data = {
              ...dataCreate,
              branch: auth.user.branchSelected.idBranch,
            };
          }
          data = {
            ...data,
            idResponsibleUser: auth.user.idUser,
            nameResponsibleUser: auth.user.name,
          };
          const response = yield call(httpsPostFetch, path, data, company);

          if (response.status === 200) {
            yield put({ type: 'SEND_SUCCESS' });
            yield put({ type: IS_OK_MASSIVE_UPLOAD });
          } else {
            const responseBodyPayload = yield call(responseBody, response);
            const translateError = yield call(
              httpsPostFetchGoogleApi,
              responseBodyPayload.message
            );
            yield put({
              type: 'SEND_FAILED',
              payload: { dataLoad: { errorMessage: translateError } },
            });
          }
        } catch (e) {
          yield put({
            type: 'SEND_FAILED',
            payload: { dataLoad: { errorMessage: '' } },
          });
          console.error('error https post with response' + name, e);
        }
      };
    },
  },
};
