export function processAuthConditions(auth, conditionsBase) {
  if (
    auth.user.typeProfileName === 'maintenanceStaff' ||
    auth.user.typeProfileName === 'operator'
  ) {
    conditionsBase.push({
      fieldPath: 'branch',
      opt: '==',
      value: auth.user?.branch,
    });
    return;
  }
  if (auth.user.branchSelected && auth.user.branchSelected !== 'todos') {
    conditionsBase.push({
      fieldPath: 'branch',
      opt: '==',
      value: auth.user.branchSelected?.idBranch,
    });
  }
}

export function updateDataWithBranch(auth, dataCreate) {
  let data = { ...dataCreate };

  if (
    auth.user.typeProfileName === 'maintenanceStaff' ||
    auth.user.typeProfileName === 'operator'
  ) {
    data = {
      ...dataCreate,
      branch: auth.user.branch,
    };
    return data;
  }

  if (auth.user.branchSelected && auth.user.branchSelected !== 'todos') {
    data = {
      ...dataCreate,
      branch: auth.user.branchSelected?.idBranch,
    };
  }

  return data;
}
