import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import Alerts from './Alerts';
import {
  sendMaintenancePlan,
  ChangeStockMaterials,
  StockMaterials,
  Materials,
  AdministrativeUsers,
  OperatorsUsers,
  MaintenanceStaffUsers,
  branch,
  listReserveTime,
  listOperationalLost,
  listShift,
  listSpecialitys,
  listTypeHolidays,
  listTypeWork,
  listTypeVehicles,
  listBrand,
  listModels,
  listUnitMeasurement,
  Test,
  SpareParts,
  StockSpareParts,
  AddStockSpareParts,
  ChangeStockSpareParts,
  WorkOrder,
  WorkOrderInform,
  LaborRegistration,
  LaborRegistrationInform,
  Fleets,
  Vehicles,
  Systems,
  Components,
  Operations,
  Backlogs,
  NoProductionDays,
  listTypeUser,
  requestBacklogs,
  listPosition,
  listShiftSystems,
  AddStockMaterials,
  GraphicsMaintenanceVehicle,
  GraphicsMaintenanceEquipment,
  GraphicsOperations,
  GraphicsWorkOrder,
  GraphicsMaintenanceProgramWeek,
  GraphicsMaintenanceProgramMonth,
  GraphicsMaintenanceCostFleet,
  GraphicsMaintenanceCostVehicle,
  GraphicsMaintenanceCostSystem,
  GraphicsOil,
  GraphicsBacklogs,
  GraphicsStockLeadTime,
  ReserveTimes,
  Oil,
  KmHr,
  WasteOperational,
  RequestMaterials,
  RequestSpareParts,
  TransferSpareParts,
  Companies,
  Licences,
  RequestTransferSpareParts,
  MaintenanceInterval,
  ConditionMonitoring,
  MinorComponentsReplacement,
  MayorComponentsReplacement,
  ImprovementProduct,
  Inspection,
  LaborRegistrationWithOt,
  Currency,
  listGroups,
  MassiveUploadVehicles,
  MassiveUploadSpareParts,
  MassiveUploadWorkOrder,
  modifyFiles,
  Activities,
  supplier,
  supplierMaterials,
  modifyFilesDocument,
  StockSparePartOtherBranch,
  GraphicsLabor,
  GraphicsCriticality,
  GraphicsCriticalitySystems,
  GraphicsCriticalityComponents,
  GraphicsFailureCause,
  WorkOrdersHistorical,
  AddStockOt,
  LaborRegistrationWithOtJustList,
  CreateWorkOrdersExcel,
  MassiveUploadOperator,
  MassiveUploadMaintenance,
  UserCompanies,
  branchCompanies,
  listPositionCompanies,
  MaintenanceStaffUsersSpecial,
  SparePartOtherBranch,
  WorkOrderKmHr,
  GraphicsStockRotationMonthly,
  GraphicsStockRotationAnnually,
  FleetsOtherBranch,
  ActivitiesTwo,
  ActivitiesThisMonth,
  MassiveUploadStockSpareParts,
  MassiveUploadKmHr,
  MassiveUploadOil,
  InformUseMaterialsWithOut,
  MaterialsWithOutOt,
  notifications,
  MassiveUploadStockMaterials,
  MassiveUploadMaterials,
  MassiveUploadSystems,
  MassiveUploadAdjustStockMaterials,
  MassiveUploadAdjustStockSparePart,
  MassiveUploadSupplier,
  MassiveUploadMaterialSuppliers,
  SparePartEntryReport,
  MaterialEntryReport,
  MaintenanceGuideline,
  MaintenanceGuidelineAssigned,
  MassiveUploadTransferSpareParts,
  MassiveUploadTransferMaterials,
  AlertsPlan,
} from '../builder';

// MaintenanceGuideline
const MaintenanceGuidelineReducer =
  MaintenanceGuideline.getReducers().reducer();

// MaintenanceGuidelineAssigned
const MaintenanceGuidelineAssignedReducer =
  MaintenanceGuidelineAssigned.getReducers().reducer();

// register
const sparePartEntryReportReducer =
  SparePartEntryReport.getReducers().reducer();
const materialEntryReportReducer = MaterialEntryReport.getReducers().reducer();

const testReducer = Test.getReducers().reducer();
// criticality
const graphicsCriticalityReducer = GraphicsCriticality.getReducers().reducer();
const graphicsCriticalitySystemsReducer =
  GraphicsCriticalitySystems.getReducers().reducer();
const GraphicsCriticalityComponentsReducer =
  GraphicsCriticalityComponents.getReducers().reducer();
const graphicsFailureCausesReducer =
  GraphicsFailureCause.getReducers().reducer();

// productivity
const graphicsLaborReducer = GraphicsLabor.getReducers().reducer();
// files
const modifyFilesReducer = modifyFiles.getReducers().reducer();
const modifyFilesDocumentReducer = modifyFilesDocument.getReducers().reducer();
// upload massive
const MassiveUploadVehiclesReducer =
  MassiveUploadVehicles.getReducers().reducer();
const MassiveUploadSystemsReducer =
  MassiveUploadSystems.getReducers().reducer();
const MassiveUploadSparePartsReducer =
  MassiveUploadSpareParts.getReducers().reducer();
const MassiveUploadTransferSparePartsReducer =
  MassiveUploadTransferSpareParts.getReducers().reducer();
const MassiveUploadTransferMaterialsReducer =
  MassiveUploadTransferMaterials.getReducers().reducer();
const MassiveUploadStockSparePartsReducer =
  MassiveUploadStockSpareParts.getReducers().reducer();
const MassiveUploadMaterialsReducer =
  MassiveUploadMaterials.getReducers().reducer();
const MassiveUploadStockMaterialsReducer =
  MassiveUploadStockMaterials.getReducers().reducer();
const MassiveUploadAdjustStockMaterialsReducer =
  MassiveUploadAdjustStockMaterials.getReducers().reducer();
const MassiveUploadAdjustStockSparePartReducer =
  MassiveUploadAdjustStockSparePart.getReducers().reducer();
const MassiveUploadSupplierReducer =
  MassiveUploadSupplier.getReducers().reducer();
const MassiveUploadWorkOrderReducer =
  MassiveUploadWorkOrder.getReducers().reducer();
const MassiveUploadKmHrReducer = MassiveUploadKmHr.getReducers().reducer();
const MassiveUploadOilReducer = MassiveUploadOil.getReducers().reducer();
const MassiveUploadOperatorReducer =
  MassiveUploadOperator.getReducers().reducer();
const MassiveUploadMaintenanceReducer =
  MassiveUploadMaintenance.getReducers().reducer();
const MassiveUploadMaterialSuppliersReducer =
  MassiveUploadMaterialSuppliers.getReducers().reducer();

// materials
const materialsReducer = Materials.getReducers().reducer();
const changeStockMaterialsReducer =
  ChangeStockMaterials.getReducers().reducer();
const materialsWithOutOtReducer = MaterialsWithOutOt.getReducers().reducer();
const stockMaterialsReducer = StockMaterials.getReducers().reducer();
const informUseMaterialsWithOutReducer =
  InformUseMaterialsWithOut.getReducers().reducer();
const AddStockMaterialsReducer = AddStockMaterials.getReducers().reducer();
const RequestMaterialsReducer = RequestMaterials.getReducers().reducer();
// users
const administrativeUsersReducer = AdministrativeUsers.getReducers().reducer();
const operatorsUsersReducer = OperatorsUsers.getReducers().reducer();
const maintenanceStaffUsersReducer =
  MaintenanceStaffUsers.getReducers().reducer();
const maintenanceStaffUsersSpecialReducer =
  MaintenanceStaffUsersSpecial.getReducers().reducer();
// settings
const notificationsReducer = notifications.getReducers().reducer();
const supplierReducer = supplier.getReducers().reducer();
const supplierMaterialsReducer = supplierMaterials.getReducers().reducer();
const branchReducer = branch.getReducers().reducer();
const listReserveTimeReducer = listReserveTime.getReducers().reducer();
const listOperationalLostReducer = listOperationalLost.getReducers().reducer();
const listShiftReducer = listShift.getReducers().reducer();
const listSpecialitysReducer = listSpecialitys.getReducers().reducer();
const listTypeHolidaysReducer = listTypeHolidays.getReducers().reducer();
const listTypeWorkReducer = listTypeWork.getReducers().reducer();
const listPositionReducer = listPosition.getReducers().reducer();
const listTypeVehiclesReducer = listTypeVehicles.getReducers().reducer();
const listBrandReducer = listBrand.getReducers().reducer();
const listModelsReducer = listModels.getReducers().reducer();
const listTypeUserReducer = listTypeUser.getReducers().reducer();
const listUnitMeasurementReducer = listUnitMeasurement.getReducers().reducer();
const listShiftSystemsReducer = listShiftSystems.getReducers().reducer();
const listGroupsReducer = listGroups.getReducers().reducer();
// spare parts
const sparePartsReducer = SpareParts.getReducers().reducer();
const requestSparePartsReducer = RequestSpareParts.getReducers().reducer();
const TransferSparePartsReducer = TransferSpareParts.getReducers().reducer();
const RequestTransferSparePartsReducer =
  RequestTransferSpareParts.getReducers().reducer();
const stockSparePartsReducer = StockSpareParts.getReducers().reducer();
const changeStockSparePartsReducer =
  ChangeStockSpareParts.getReducers().reducer();
const AddStockSparePartsReducer = AddStockSpareParts.getReducers().reducer();
// work order
const workOrderReducer = WorkOrder.getReducers().reducer();
const workOrderKmHrReducer = WorkOrderKmHr.getReducers().reducer();
const workOrderInformReducer = WorkOrderInform.getReducers().reducer();
const WorkOrdersHistoricalReducer =
  WorkOrdersHistorical.getReducers().reducer();
const addStockOtReducer = AddStockOt.getReducers().reducer();
const createWorkOrdersExcelReducer =
  CreateWorkOrdersExcel.getReducers().reducer();
// labor Registration
const laborRegistrationReducer = LaborRegistration.getReducers().reducer();
const laborRegistrationWithOtJustListReducer =
  LaborRegistrationWithOtJustList.getReducers().reducer();
const laborRegistrationWithOtReducer =
  LaborRegistrationWithOt.getReducers().reducer();
const laborRegistrationInformReducer =
  LaborRegistrationInform.getReducers().reducer();
// vehicles
const fleetsReducer = Fleets.getReducers().reducer();
const vehiclesReducer = Vehicles.getReducers().reducer();
const systemsReducer = Systems.getReducers().reducer();
const componentsReducer = Components.getReducers().reducer();
// operations
const operationsReducer = Operations.getReducers().reducer();
const reserveTimesReducer = ReserveTimes.getReducers().reducer();
const oilReducer = Oil.getReducers().reducer();
const kmHrReducer = KmHr.getReducers().reducer();
const wasteOperationalReducer = WasteOperational.getReducers().reducer();
const noProductionDaysReducer = NoProductionDays.getReducers().reducer();

// activities
const sendMaintenancePlanReducer = sendMaintenancePlan.getReducers().reducer();
const alertsPlanReducer = AlertsPlan.getReducers().reducer();
const activitiesReducer = Activities.getReducers().reducer();
const activitiesTwoReducer = ActivitiesTwo.getReducers().reducer();
const activitiesThisMonthReducer = ActivitiesThisMonth.getReducers().reducer();
const backlogsReducer = Backlogs.getReducers().reducer();
const requestBacklogsReducer = requestBacklogs.getReducers().reducer();
const MaintenanceIntervalReducer = MaintenanceInterval.getReducers().reducer();
const ConditionMonitoringReducer = ConditionMonitoring.getReducers().reducer();
const MinorComponentsReplacementReducer =
  MinorComponentsReplacement.getReducers().reducer();
const MayorComponentsReplacementReducer =
  MayorComponentsReplacement.getReducers().reducer();
const ImprovementProductReducer = ImprovementProduct.getReducers().reducer();
const InspectionReducer = Inspection.getReducers().reducer();

//  graphics
const graphicsMaintenanceVehicleReducer =
  GraphicsMaintenanceVehicle.getReducers().reducer();
const graphicsMaintenanceEquipmentReducer =
  GraphicsMaintenanceEquipment.getReducers().reducer();
// operations
const graphicsOperationsReducer = GraphicsOperations.getReducers().reducer();
// work order
const GraphicsWorkOrderReducer = GraphicsWorkOrder.getReducers().reducer();
// maintenance program
const GraphicsMaintenanceProgramWeekReducer =
  GraphicsMaintenanceProgramWeek.getReducers().reducer();
const GraphicsMaintenanceProgramMonthReducer =
  GraphicsMaintenanceProgramMonth.getReducers().reducer();
// maintenance cost
const GraphicsMaintenanceCostFleetReducer =
  GraphicsMaintenanceCostFleet.getReducers().reducer();
const GraphicsMaintenanceCostVehicleReducer =
  GraphicsMaintenanceCostVehicle.getReducers().reducer();
const GraphicsMaintenanceCostSystemReducer =
  GraphicsMaintenanceCostSystem.getReducers().reducer();
// oil
const GraphicsOilReducer = GraphicsOil.getReducers().reducer();
// backlogs
const GraphicsBacklogsReducer = GraphicsBacklogs.getReducers().reducer();
// stock
const GraphicsStockLeadTimeReducer =
  GraphicsStockLeadTime.getReducers().reducer();
const GraphicsStockRotationMonthlyReducer =
  GraphicsStockRotationMonthly.getReducers().reducer();
const GraphicsStockRotationAnnuallyReducer =
  GraphicsStockRotationAnnually.getReducers().reducer();
// admin
const CompaniesReducer = Companies.getReducers().reducer();
const UserCompaniesReducer = UserCompanies.getReducers().reducer();
const BranchCompaniesReducer = branchCompanies.getReducers().reducer();
const ListPositionCompaniesReducer = listPositionCompanies
  .getReducers()
  .reducer();
const LicencesReducer = Licences.getReducers().reducer();
const CurrencyReducer = Currency.getReducers().reducer();
// stock spare part other branch
const stockSparePartOtherBranchReducer =
  StockSparePartOtherBranch.getReducers().reducer();
const sparePartOtherBranchReducer =
  SparePartOtherBranch.getReducers().reducer();
const FleetsOtherBranchReducer = FleetsOtherBranch.getReducers().reducer();

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    alert: Alerts,
    common: Common,
    alertsPlan: alertsPlanReducer,
    stockSparePartOtherBranch: stockSparePartOtherBranchReducer,
    sparePartOtherBranch: sparePartOtherBranchReducer,
    fleetsOtherBranch: FleetsOtherBranchReducer,
    modifyFiles: modifyFilesReducer,
    modifyFilesDocument: modifyFilesDocumentReducer,
    massiveUploadKmHr: MassiveUploadKmHrReducer,
    massiveUploadOil: MassiveUploadOilReducer,
    massiveUploadVehicles: MassiveUploadVehiclesReducer,
    massiveUploadSystems: MassiveUploadSystemsReducer,
    massiveUploadSpareParts: MassiveUploadSparePartsReducer,
    massiveUploadTransferSpareParts: MassiveUploadTransferSparePartsReducer,
    massiveUploadTransferMaterials: MassiveUploadTransferMaterialsReducer,
    massiveUploadStockSpareParts: MassiveUploadStockSparePartsReducer,
    massiveUploadMaterials: MassiveUploadMaterialsReducer,
    massiveUploadStockMaterials: MassiveUploadStockMaterialsReducer,
    massiveUploadWorkOrder: MassiveUploadWorkOrderReducer,
    massiveUploadOperator: MassiveUploadOperatorReducer,
    massiveUploadMaintenance: MassiveUploadMaintenanceReducer,
    massiveUploadAdjustStockMaterials: MassiveUploadAdjustStockMaterialsReducer,
    massiveUploadAdjustStockSparePart: MassiveUploadAdjustStockSparePartReducer,
    massiveUploadSupplier: MassiveUploadSupplierReducer,
    massiveUploadMaterialSuppliers: MassiveUploadMaterialSuppliersReducer,
    maintenanceInterval: MaintenanceIntervalReducer,
    conditionMonitoring: ConditionMonitoringReducer,
    minorComponentsReplacement: MinorComponentsReplacementReducer,
    mayorComponentsReplacement: MayorComponentsReplacementReducer,
    improvementProduct: ImprovementProductReducer,
    inspection: InspectionReducer,
    companies: CompaniesReducer,
    userCompanies: UserCompaniesReducer,
    branchCompanies: BranchCompaniesReducer,
    listPositionCompanies: ListPositionCompaniesReducer,
    licences: LicencesReducer,
    currency: CurrencyReducer,
    reserveTimes: reserveTimesReducer,
    oil: oilReducer,
    kmHr: kmHrReducer,
    wasteOperational: wasteOperationalReducer,
    graphicsStockLeadTime: GraphicsStockLeadTimeReducer,
    graphicsStockRotationMonthly: GraphicsStockRotationMonthlyReducer,
    graphicsStockRotationAnnually: GraphicsStockRotationAnnuallyReducer,
    graphicsBacklogs: GraphicsBacklogsReducer,
    graphicsOil: GraphicsOilReducer,
    graphicsMaintenanceCostFleet: GraphicsMaintenanceCostFleetReducer,
    graphicsMaintenanceCostVehicle: GraphicsMaintenanceCostVehicleReducer,
    graphicsMaintenanceCostSystem: GraphicsMaintenanceCostSystemReducer,
    graphicsMaintenanceProgramWeek: GraphicsMaintenanceProgramWeekReducer,
    graphicsMaintenanceProgramMonth: GraphicsMaintenanceProgramMonthReducer,
    graphicsWorkOrder: GraphicsWorkOrderReducer,
    graphicsOperations: graphicsOperationsReducer,
    graphicsMaintenanceVehicle: graphicsMaintenanceVehicleReducer,
    graphicsMaintenanceEquipment: graphicsMaintenanceEquipmentReducer,
    test: testReducer,
    materials: materialsReducer,
    requestMaterials: RequestMaterialsReducer,
    changeStockMaterials: changeStockMaterialsReducer,
    materialsWithOutOt: materialsWithOutOtReducer,
    stockMaterials: stockMaterialsReducer,
    informUseMaterialsWithOut: informUseMaterialsWithOutReducer,
    addStockMaterials: AddStockMaterialsReducer,
    administrativeUsers: administrativeUsersReducer,
    operatorsUsers: operatorsUsersReducer,
    maintenanceStaffUsers: maintenanceStaffUsersReducer,
    maintenanceStaffUsersSpecial: maintenanceStaffUsersSpecialReducer,
    supplier: supplierReducer,
    notifications: notificationsReducer,
    supplierMaterials: supplierMaterialsReducer,
    branch: branchReducer,
    listOperationalLost: listOperationalLostReducer,
    listReserveTime: listReserveTimeReducer,
    listShift: listShiftReducer,
    listSpeciality: listSpecialitysReducer,
    listTypeHolidays: listTypeHolidaysReducer,
    listTypeWork: listTypeWorkReducer,
    spareParts: sparePartsReducer,
    requestSpareParts: requestSparePartsReducer,
    transferSpareParts: TransferSparePartsReducer,
    requestTransferSpareParts: RequestTransferSparePartsReducer,
    stockSpareParts: stockSparePartsReducer,
    addStockSpareParts: AddStockSparePartsReducer,
    changeStockSpareParts: changeStockSparePartsReducer,
    workOrder: workOrderReducer,
    workOrderKmHr: workOrderKmHrReducer,
    workOrdersHistorical: WorkOrdersHistoricalReducer,
    workOrderInform: workOrderInformReducer,
    addStockOt: addStockOtReducer,
    createWorkOrdersExcel: createWorkOrdersExcelReducer,
    laborRegistration: laborRegistrationReducer,
    laborRegistrationWithOtJustList: laborRegistrationWithOtJustListReducer,
    laborRegistrationWithOt: laborRegistrationWithOtReducer,
    laborRegistrationInform: laborRegistrationInformReducer,
    listTypeVehicles: listTypeVehiclesReducer,
    listBrand: listBrandReducer,
    listModels: listModelsReducer,
    listTypeUser: listTypeUserReducer,
    fleets: fleetsReducer,
    vehicles: vehiclesReducer,
    systems: systemsReducer,
    components: componentsReducer,
    operations: operationsReducer,
    noProductionDays: noProductionDaysReducer,
    sendMaintenancePlan: sendMaintenancePlanReducer,
    activities: activitiesReducer,
    activitiesTwo: activitiesTwoReducer,
    activitiesThisMonth: activitiesThisMonthReducer,
    backlogs: backlogsReducer,
    requestBacklogs: requestBacklogsReducer,
    listUnitMeasurement: listUnitMeasurementReducer,
    listPosition: listPositionReducer,
    listShiftSystem: listShiftSystemsReducer,
    listGroups: listGroupsReducer,
    graphicsLabor: graphicsLaborReducer,
    graphicsCriticality: graphicsCriticalityReducer,
    graphicsCriticalitySystems: graphicsCriticalitySystemsReducer,
    graphicsCriticalityComponents: GraphicsCriticalityComponentsReducer,
    graphicsFailureCauses: graphicsFailureCausesReducer,
    sparePartEntryReport: sparePartEntryReportReducer,
    materialEntryReport: materialEntryReportReducer,
    maintenanceGuideline: MaintenanceGuidelineReducer,
    maintenanceGuidelineAssigned: MaintenanceGuidelineAssignedReducer,
  });
export default reducers;
