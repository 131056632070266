import { Builder } from './builder';

export const notifications = new Builder('NOTIFICATIONS', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

// MaintenanceGuideline

export const MaintenanceGuideline = new Builder('MAINTENANCE_GUIDELINE', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

// MaintenanceGuidelineAssigned

export const MaintenanceGuidelineAssigned = new Builder(
  'MAINTENANCE_GUIDELINE_ASSIGNED',
  ['HTTPS_POST', 'UPDATE', 'LOAD', 'DELETE']
);

// reporting

export const SparePartEntryReport = new Builder('SPARE_PART_ENTRY_REPORT', [
  'HTTPS_POST',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MaterialEntryReport = new Builder('MATERIAL_ENTRY_REPORT', [
  'HTTPS_POST',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

// settings

export const branch = new Builder('BRANCH', [
  'HTTPS_POST',
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listShift = new Builder('LIST_SHIFT', [
  'CREATE',
  'UPDATE',
  'LOAD',
]);

export const supplier = new Builder('SUPPLIER', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const supplierMaterials = new Builder('SUPPLIER_MATERIALS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listReserveTime = new Builder('LIST_RESERVE_TIME', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listOperationalLost = new Builder('LIST_OPERATIONAL_LOST', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listSpecialitys = new Builder('LIST_SPECIALITYS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listUnitMeasurement = new Builder('LIST_UNIT_MEASUREMENT', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listTypeHolidays = new Builder('LIST_TYPE_HOLIDAYS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listTypeWork = new Builder('LIST_TYPE_WORK', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listPosition = new Builder('LIST_POSITION', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listTypeVehicles = new Builder('LIST_TYPE_VEHICLES', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listBrand = new Builder('LIST_BRAND', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listModels = new Builder('LIST_MODELS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listTypeUser = new Builder('LIST_TYPES_USERS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listGroups = new Builder('LIST_GROUPS', [
  'CREATE',
  'UPDATE',
  'LOAD',
]);

export const listShiftSystems = new Builder('LIST_SHIFT_SYSTEMS', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

// test

export const Test = new Builder('PRUEBA', [
  'CREATE',
  'LOAD',
  'LOAD_FIREBASE_DATABASE',
  'DELETE',
]);

// MODIFY FILES
export const modifyFiles = new Builder('MODIFY_FILES', [
  'DELETE_FILE',
  'SET_FILE',
]);

export const modifyFilesDocument = new Builder('MODIFY_FILES_DOCUMENT', [
  'DELETE_FILE_DOCUMENT',
  'UPDATE_FILE_DOCUMENT',
]);

// Massive upload

export const MassiveUploadVehicles = new Builder('MASSIVE_UPLOAD_VEHICLES', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
]);

export const MassiveUploadSystems = new Builder('MASSIVE_UPLOAD_SYSTEMS', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
]);

export const MassiveUploadSpareParts = new Builder(
  'MASSIVE_UPLOAD_SPARE_PART',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadStockSpareParts = new Builder(
  'MASSIVE_UPLOAD_STOCK_SPARE_PART',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadTransferSpareParts = new Builder(
  'MASSIVE_UPLOAD_TRANSFER_SPARE_PART',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadMaterials = new Builder('MASSIVE_UPLOAD_MATERIALS', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MassiveUploadMaterialSuppliers = new Builder(
  'MASSIVE_UPLOAD_MATERIAL_SUPPLIERS',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadStockMaterials = new Builder(
  'MASSIVE_UPLOAD_STOCK_MATERIALS',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadTransferMaterials = new Builder(
  'MASSIVE_UPLOAD_TRANSFER_MATERIALS',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadAdjustStockMaterials = new Builder(
  'MASSIVE_UPLOAD_ADJUST_STOCK_MATERIALS',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadAdjustStockSparePart = new Builder(
  'MASSIVE_UPLOAD_ADJUST_STOCK_SPARE_PART',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadWorkOrder = new Builder('MASSIVE_UPLOAD_WORK_ORDER', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MassiveUploadOperator = new Builder('MASSIVE_UPLOAD_OPERATOR', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MassiveUploadMaintenance = new Builder(
  'MASSIVE_UPLOAD_MAINTENANCE',
  ['HTTPS_POST_MASSIVE_UPLOAD', 'LOAD', 'UPDATE', 'DELETE']
);

export const MassiveUploadOil = new Builder('MASSIVE_UPLOAD_Oil', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MassiveUploadSupplier = new Builder('MASSIVE_UPLOAD_SUPPLIER', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const MassiveUploadKmHr = new Builder('MASSIVE_UPLOAD_KMHR', [
  'HTTPS_POST_MASSIVE_UPLOAD',
  'LOAD',
  'UPDATE',
  'DELETE',
]);
// stock spare part other branch

export const StockSparePartOtherBranch = new Builder(
  'STOCK_SPARE_PART_OTHER_BRANCH',
  ['HTTPS_POST_WITH_FILES', 'LOAD_WITH_OUT_BRANCH', 'UPDATE', 'DELETE']
);

export const SparePartOtherBranch = new Builder('SPARE_PART_OTHER_BRANCH', [
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

export const FleetsOtherBranch = new Builder('FLEET_OTHER_BRANCH', [
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

// MaintData

export const Companies = new Builder('COMPANIES', [
  'HTTPS_POST_WITH_FILES',
  'LOAD_COLLECTION_GROUP',
  'UPDATE',
  'DELETE',
]);

export const UserCompanies = new Builder('USER_COMPANIES', [
  'HTTPS_POST_WITH_FILES',
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

export const branchCompanies = new Builder('BRANCH_COMPANIES', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const listPositionCompanies = new Builder('LIST_POSITION_COMPANIES', [
  'CREATE_FIREBASE_DATABASE',
  'UPDATE_FIREBASE_DATABASE',
  'LOAD_FIREBASE_DATABASE',
]);

export const Licences = new Builder('LICENCES', [
  'CREATE_WITH_OUT_BRANCH',
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

export const Currency = new Builder('CURRENCY', [
  'CREATE_WITH_OUT_BRANCH',
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

// work Order

export const WorkOrder = new Builder('WORK_ORDER', [
  'HTTPS_POST_WITH_FILES',
  'CREATE',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const WorkOrderKmHr = new Builder('WORK_ORDER_KM_HR', [
  'LOAD_LIMIT_TO_ONE',
]);

export const WorkOrdersHistorical = new Builder('WORK_ORDERS_HISTORICAL', [
  'LOAD_WITH_OUT_BRANCH',
  'UPDATE',
  'DELETE',
]);

export const CreateWorkOrdersExcel = new Builder('CREATE_WORK_ORDERS_EXCEL', [
  'HTTPS_POST_WITH_RESPONSE',
]);

export const WorkOrderInform = new Builder('WORK_ORDER_INFORM', [
  'LOAD',
  'HTTPS_POST',
]);

// work Order

export const LaborRegistration = new Builder('LABOR_REGISTRATION', [
  'HTTPS_POST',
  'HTTPS_POST_DELETE',
  'CREATE',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const LaborRegistrationWithOtJustList = new Builder(
  'LABOR_REGISTRATION_WITH_OT_JUST_LIST',
  ['LOAD']
);

export const LaborRegistrationWithOt = new Builder(
  'LABOR_REGISTRATION_WITH_OT',
  ['HTTPS_POST', 'HTTPS_POST_DELETE', 'CREATE', 'LOAD', 'UPDATE', 'DELETE']
);

export const LaborRegistrationInform = new Builder(
  'LABOR_REGISTRATION_INFORM',
  ['LOAD']
);

// spare parts

export const SpareParts = new Builder('SPARE_PARTS', [
  'HTTPS_POST',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const RequestSpareParts = new Builder('REQUEST_SPARE_PARTS', [
  'HTTPS_POST',
  'CREATE',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const TransferSpareParts = new Builder('TRANSFER_SPARE_PARTS', [
  'CREATE',
  'LOAD',
  'UPDATE',
  'DELETE',
]);
export const RequestTransferSpareParts = new Builder(
  'REQUEST_TRANSFER_SPARE_PARTS',
  ['CREATE', 'LOAD', 'UPDATE', 'DELETE']
);

export const StockSpareParts = new Builder('STOCK_SPARE_PARTS', ['LOAD']);

export const AddStockSpareParts = new Builder('ADD_STOCK_SPARE_PARTS', [
  'HTTPS_POST',
  'LOAD',
]);

export const ChangeStockSpareParts = new Builder('CHANGE_STOCK_SPARE_PARTS', [
  'HTTPS_POST',
  'HTTPS_POST_DELETE',
  'LOAD',
]);

export const AddStockOt = new Builder('ADD_STOCK_OT', [
  'HTTPS_POST',
  'HTTPS_POST_DELETE',
]);

// materials

export const Materials = new Builder('MATERIALS', [
  'HTTPS_POST',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const RequestMaterials = new Builder('REQUEST_MATERIALS', [
  'HTTPS_POST',
  'CREATE',
  'LOAD',
  'UPDATE',
  'DELETE',
]);

export const StockMaterials = new Builder('STOCK_MATERIALS', ['LOAD']);

export const InformUseMaterialsWithOut = new Builder(
  'INFORM_USE_MATERIAL_WITH_OUT',
  ['LOAD']
);

export const AddStockMaterials = new Builder('ADD_STOCK_MATERIALS', [
  'HTTPS_POST',
  'LOAD',
]);

export const ChangeStockMaterials = new Builder('CHANGE_STOCK_MATERIALS', [
  'HTTPS_POST',
  'HTTPS_POST_DELETE',
  'LOAD',
]);

export const MaterialsWithOutOt = new Builder('MaterialsWithOutOt', [
  'HTTPS_POST',
  'HTTPS_POST_DELETE',
  'LOAD',
]);

// Users

export const AdministrativeUsers = new Builder('ADMINISTRATIVE_USERS', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'HTTPS_POST_DELETE',
]);

export const MaintenanceStaffUsers = new Builder('MAINTENANCE_STAFF_USERS', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'HTTPS_POST_DELETE',
]);

export const MaintenanceStaffUsersSpecial = new Builder(
  'MAINTENANCE_STAFF_USERS_SPECIAL',
  ['HTTPS_POST_WITH_FILES', 'UPDATE', 'LOAD', 'HTTPS_POST_DELETE']
);

export const OperatorsUsers = new Builder('OPERATORS_USERS', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'HTTPS_POST_DELETE',
]);

// vehicles
export const Fleets = new Builder('FLEETS', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const Vehicles = new Builder('VEHICLES', [
  'HTTPS_POST',
  'CREATE_WITH_FILES',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const Systems = new Builder('SYSTEMS', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const Components = new Builder('COMPONENTS', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

// operations
export const Operations = new Builder('OPERATIONS', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const Oil = new Builder('Oil', ['CREATE', 'UPDATE', 'LOAD', 'DELETE']);

export const KmHr = new Builder('KMHR', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const WasteOperational = new Builder('WASTE_OPERATIONAL', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const ReserveTimes = new Builder('RESERVE_TIME', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const NoProductionDays = new Builder('NO_PRODUCTION_DAYS', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

// maintenance
export const sendMaintenancePlan = new Builder('SEND_MAINTENANCE_PLAN', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const AlertsPlan = new Builder('ALERTS_PLAN', [
  'CREATE',
  'UPDATE',
  'LOAD',
  'DELETE',
]);

export const Activities = new Builder('ACTIVITIES', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'CREATE',
  'LOAD',
  'DELETE',
]);

export const ActivitiesTwo = new Builder('ACTIVITIES', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'CREATE',
  'LOAD',
  'DELETE',
]);

export const ActivitiesThisMonth = new Builder('ACTIVITIES_THIS_MONTH', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'CREATE',
  'LOAD',
  'DELETE',
]);

export const Backlogs = new Builder('BACKLOGS', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const requestBacklogs = new Builder('REQUEST_BACKLOGS', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const MaintenanceInterval = new Builder('MAINTENANCE_INTERVAL', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const ConditionMonitoring = new Builder('CONDITION_MONITORING', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const MinorComponentsReplacement = new Builder(
  'MINOR_COMPONENTS_REPLACEMENT',
  ['HTTPS_POST', 'UPDATE', 'LOAD', 'DELETE']
);
export const MayorComponentsReplacement = new Builder(
  'MAYOR_COMPONENTS_REPLACEMENT',
  ['HTTPS_POST', 'UPDATE', 'LOAD', 'DELETE']
);
export const ImprovementProduct = new Builder('IMPROVEMENT_PRODUCT', [
  'HTTPS_POST_WITH_FILES',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
export const Inspection = new Builder('INSPECTION', [
  'HTTPS_POST',
  'UPDATE',
  'LOAD',
  'DELETE',
]);
// graphics
export const GraphicsMaintenanceVehicle = new Builder(
  'GRAPHICS_MAINTENANCE_VEHICLE_AVAILABILITY',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsMaintenanceEquipment = new Builder(
  'GRAPHICS_MAINTENANCE_EQUIPMENT_AVAILABILITY',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);

// operations

export const GraphicsOperations = new Builder('GRAPHICS_OPERATIONS', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);

// work order
export const GraphicsWorkOrder = new Builder('GRAPHICS_WORK_ORDER', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);
export const GraphicsWorkOrderValueMonth = new Builder(
  'GRAPHICS_WORK_ORDER_VALUE_MONTH',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
// labor productivity
export const GraphicsMaintenanceProgramWeek = new Builder(
  'GRAPHICS_MAINTENANCE_PROGRAM_WEEK',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsMaintenanceProgramMonth = new Builder(
  'GRAPHICS_MAINTENANCE_PROGRAM_MONTH',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
// maintenace cost
export const GraphicsMaintenanceCostFleet = new Builder(
  'GRAPHICS_MAINTENANCE_COST_FLEET',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsMaintenanceCostVehicle = new Builder(
  'GRAPHICS_MAINTENANCE_COST_VEHICLE',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsMaintenanceCostSystem = new Builder(
  'GRAPHICS_MAINTENANCE_COST_SYSTEM',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
// oil
export const GraphicsOil = new Builder('GRAPHICS_OIL_PERFORMANCE', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);
// backLogs
export const GraphicsBacklogs = new Builder('GRAPHICS_BACKLOGS', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);

// stock

export const GraphicsStockLeadTime = new Builder(
  'GRAPHICS_STOCK_LEAD_TIME_MONTH',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsStockRotationMonthly = new Builder(
  'GRAPHICS_STOCK_ROTATION_MONTH_MONTHLY',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsStockRotationAnnually = new Builder(
  'GRAPHICS_STOCK_ROTATION_MONTH_ANNUALLY',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsLabor = new Builder('GRAPHICS_LABOR', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);

// criticality
export const GraphicsCriticality = new Builder('GRAPHICS_CRITICALITY', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);
export const GraphicsCriticalitySystems = new Builder(
  'GRAPHICS_CRITICALITY_SYSTEMS',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);
export const GraphicsCriticalityComponents = new Builder(
  'GRAPHICS_CRITICALITY_COMPONENTS',
  ['LOAD', 'HTTPS_POST_WITH_RESPONSE']
);

// failure cause
export const GraphicsFailureCause = new Builder('GRAPHICS_FAILURE_CAUSE', [
  'LOAD',
  'HTTPS_POST_WITH_RESPONSE',
]);
