import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  IS_OK_MASSIVE_UPLOAD,
  RESET_IS_OK_MASSIVE_UPLOAD,
  SET_SELECTED_WORK_ORDER,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  isOkMassiveUpload: false,
  selectedWorkOrderId: null,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case UPDATING_CONTENT: {
      return { ...state, error: '', message: '', updatingContent: true };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case IS_OK_MASSIVE_UPLOAD: {
      return {
        ...state,
        isOkMassiveUpload: true,
      };
    }

    case RESET_IS_OK_MASSIVE_UPLOAD: {
      return {
        ...state,
        isOkMassiveUpload: false,
      };
    }
    case SET_SELECTED_WORK_ORDER:
      return {
        ...state,
        selectedWorkOrderId: action.payload,
      };
    default:
      return state;
  }
};
export default commonReducer;
