import { put, take, takeEvery } from '@redux-saga/core/effects';
import { loadCollectionQueryFirestore } from '../services/firebase/firestore';

export const operationLoadWithOutBranch = {
  name: 'LOAD_WITH_OUT_BRANCH',
  reducer: {
    initialState: { loading: false, loaded: false, loadFailed: false },
    cases: [
      {
        name: 'LOAD_WITH_OUT_BRANCH_',
        newState: { loading: true, loaded: false, loadFailed: false },
      },
      {
        name: 'LOADED_WITH_OUT_BRANCH_',
        newState: { loading: false, loaded: true, loadFailed: false },
      },
      {
        name: 'LOAD_FAILED_WITH_OUT_BRANCH_',
        newState: { loading: false, loaded: false, loadFailed: true },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        load: (payload) => {
          return { type: 'LOAD_WITH_OUT_BRANCH_' + name, payload: payload };
        },
        loaded: (payload) => {
          return { type: 'LOADED_WITH_OUT_BRANCH_' + name, payload: payload };
        },
        loadFailed: () => {
          return { type: 'LOAD_FAILED_WITH_OUT_BRANCH_' + name };
        },
        stopLoad: () => {
          return { type: 'STOP_LOAD_WITH_OUT_BRANCH_' + name };
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'LOAD_WITH_OUT_BRANCH_',
    function: (name) => {
      return function* load({ payload }) {
        yield put({ type: 'LOAD_ALERT' });
        const { path, idName, conditions } = payload;
        const conditionsBase = conditions.concat([]);
        const Channel = loadCollectionQueryFirestore(
          path,
          idName,
          conditionsBase
        );
        yield takeEvery(Channel, function* (action) {
          yield put({
            type: 'LOADED_WITH_OUT_BRANCH_' + name,
            payload: { dataLoad: { list: action.data } },
          });
          yield put({
            type: 'LOADED_ALERT',
          });
        });
        yield take([
          'LOAD_WITH_OUT_BRANCH_' + name,
          'STOP_LOAD_WITH_OUT_BRANCH_' + name,
          'STOP_LOAD_EVERYTHING',
        ]);
        Channel.close();
        yield put({
          type: 'LOADED_WITH_OUT_BRANCH_' + name,
          payload: { dataLoad: { list: [] } },
        });
      };
    },
  },
};
