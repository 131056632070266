import { put, call, select } from '@redux-saga/core/effects';
import { setDocumentFirestore } from '../services/firebase/firestore';

const getAuth = (state) => state.auth;

export const operationCreate = {
  name: 'CREATE',
  reducer: {
    initialState: { creating: false, created: false, createFailed: false },
    cases: [
      {
        name: 'CREATE_',
        newState: { creating: true, created: false, createFailed: false },
      },
      {
        name: 'CREATED_',
        newState: { creating: false, created: true, createFailed: false },
      },
      {
        name: 'CREATE_FAILED_',
        newState: { creating: false, created: false, createFailed: true },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        create: (payload) => {
          return { type: 'CREATE_' + name, payload: payload };
        },
        created: () => {
          return { type: 'CREATED_' + name };
        },
        createFailed: () => {
          return { type: 'CREATE_FAILED_' + name };
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'CREATE_',
    function: (name) => {
      return function* create({ payload }) {
        try {
          yield put({ type: 'LOAD_ALERT' });
          const { path, dataCreate } = payload;
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== 'todos'
          ) {
            const dataWithBranch = {
              ...dataCreate,
              branch: auth.user.branchSelected.idBranch,
            };
            yield call(setDocumentFirestore, path, dataWithBranch);
            yield put({ type: 'CREATED_SUCCESS' });
          } else {
            yield put({ type: 'BRANCH_NO_SELECTED' });
            yield put({ type: 'CREATED_FAILED' });
          }
        } catch (e) {
          yield put({ type: 'CREATED_FAILED' });
        }
      };
    },
  },
};
